<template>
  <div class="box nomargin">
    <p class="heading">Org Import/Create</p>
    <documentation
      v-if="$store.state.showDocs"
      comp="organization-create"
      slug="organizationCreate"
    ></documentation>
    <hr />
    <div class="field">
      <b-switch v-model="newOrg.cargowise_sync">CargoWise Import</b-switch>
      <hr />
    </div>
      <!-- <div class="field">
        <b-field label="Replaced by Org ID" label-position="on-border">
          <b-input type="number" v-model.number="newOrg.replaced_by_id"></b-input>
        </b-field>
      </div>  -->
    <span v-if="newOrg.cargowise_sync == true">
      <b-field label="CargoWise Org Code" label-position="on-border">
        <b-input v-model="newOrg.code"></b-input>
      </b-field>
      <div class="field">
        <b-checkbox v-model="newOrg.is_shipper">Shipper</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_consignee">Consignee</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_warehouse">Warehouse</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_billto">Bill-to</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_creditor">Creditor</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_carrier">Carrier</b-checkbox>
      </div>

      <div class="field">
        <b-checkbox v-model="newOrg.include_addresses">
          <b>Import All Addresses</b>
        </b-checkbox>
      </div>
      <b-button @click="createOrg" type="is-primary">
        <i class="fas fa-file-import"></i>
        <span class="leftmargin">Import Org</span>
      </b-button>
      <b-button
        class="button nomargin leftmargin"
        @click="isUploadModalActive = true"
        title="Open Text Area"
      >
        <i class="fas fa-table"></i>
      </b-button>
      </span>
     
    
    <span v-if="newOrg.cargowise_sync == false">
      <b-field label="Code" label-position="on-border">
        <b-input v-model="newOrg.code"></b-input>
      </b-field>
      <b-field label="Full Name" label-position="on-border">
        <b-input v-model="newOrg.fullname"></b-input>
      </b-field>
      <div class="field">
        <b-checkbox disabled>CargoWise Sync</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_shipper">Shipper</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_consignee">Consignee</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_warehouse">Warehouse</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_billto">Bill-to</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_creditor">Creditor</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="newOrg.is_carrier">Carrier</b-checkbox>
      </div>
      <b-button type="is-primary" @click="createOrg">Create Org</b-button>
    </span>
    <b-modal
      :active.sync="isUploadModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      can-cancel
    >
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body" style="width:auto">
          <p>Input</p>
          <b-input
            v-model="inputData"
            placeholder="Paste your Excel data here"
            rows="10"
            cols="120"
            type="textarea"
          ></b-input>
          <b-button
            @click="bulkCreateOrgs"
            type="is-primary"
            :disabled="loading || (inputData ? false : true)"
          >
            <i class="fas fa-file-import"></i>
            <span class="leftmargin">Bulk Import Orgs</span>
          </b-button>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/backend/axios";
export default {
  name: "OrganizationCreate",
  data() {
    return {
      loading: false,
      newOrg: {
        code: "",
        fullname: "",
        is_shipper: false,
        is_consignee: false,
        is_warehouse: false,
        is_billto: false,
        is_creditor: false,
        is_carrier: false,
        include_addresses: false,
        cargowise_sync: true,
        replaced_by_id:null
      },
      isUploadModalActive: false,
      inputData: null
    };
  },
  methods: {
    createOrg: async function() {
      if (
        this.newOrg.is_shipper == false &&
        this.newOrg.is_consignee == false &&
        this.newOrg.is_warehouse == false &&
        this.newOrg.is_billto == false &&
        this.newOrg.is_creditor == false &&
        this.newOrg.is_carrier == false
      ) {
        this.$buefy.toast.open({
          message: "You need to pick a type",
          type: "is-danger",
          position: "is-bottom-left"
        });
        return;
      }
      let org = { ...this.newOrg };
      if (org.cargowise_sync == true) {
        org.fullname = "";
      }
      try {
        const res = await axios.post("/organizations", org);
        //this.orgs.unshift(res.data);
        this.$emit("org-created", JSON.parse(JSON.stringify(res.data)));
        this.newOrg.code = this.newOrg.fullname = "";
        this.newOrg.include_addresses = this.newOrg.is_shipper = this.newOrg.is_consignee = this.newOrg.is_warehouse = this.newOrg.is_billto = this.newOrg.is_creditor = this.newOrg.is_carrier = false;
        this.$buefy.toast.open({
          message: "Organization added!",
          type: "is-success",
          position: "is-bottom"
        });
      } catch (err) {
        this.$buefy.snackbar.open({
          message: `Something went wrong!: ${err.response.data.errors}`,
          indefinite: true,
          position: "is-bottom"
        });
      }
    },
    bulkCreateOrgs: async function() {
      this.loading = true;
      try {
        await axios.post(
          `/organizations/bulk-upload`,
          JSON.stringify(this.bulkOrgArray)
        );
        this.inputData = "";
        this.$buefy.toast.open({
          message: "Orgs & Addresses were created!",
          type: "is-success",
          position: "is-bottom"
        });
        this.$emit("refresh");
        this.isUploadModalActive = false;
      } catch (err) {
        this.isLanesValidated = false;
        this.$buefy.snackbar.open({
          message: `Org & Address Create failed.` + err.response.data,
          indefinite: true
        });
      }
      this.loading = false;
    }
  },
  computed: {
    bulkOrgArray() {
      let orgArray = this.inputData.split("\n");
      var filteredOrgArray = orgArray.filter(function(item) {
        return item != "";
      });
      let dupsRemoved = [...new Set(filteredOrgArray)];
      return dupsRemoved;
    }
  }
};
</script>

<style>
</style>